/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './blog.css';
import moment from 'moment'

interface IBlog { title: string; date: string; content: React.JSX.Element}

const blogItems: IBlog[]  = [
  // {
  //   title: "",
  //   date: "",
  //   content: <></>
  // },

  {
    title: "Polishing Progress: Yoke, Spring, Bridge, and Covers",
    date: "2025-01-23 15:23:00",
    content: <>
      <h2>Polishing Progress and Next Steps</h2>
      <p>Today, I focused on polishing the <strong>yoke</strong>, <strong>spring</strong>, <strong>bridge</strong>, and <strong>covers</strong>. It’s rewarding to see these components take shape with smooth finishes and precise details. 😊</p>
      <p>Tomorrow, I’ll tackle the two wheels that will connect the sliding pinion to the minute wheel—excited to keep the momentum going!</p>
      <h1>Cheers! 😅</h1>
      <br />
      <img src='images/blog/2025-01-24/01.jpg' alt='sketch' />
      <img src='images/blog/2025-01-24/00.jpg' alt='sketch' />
    </>
  },
  {
    title: "Keyless Works: Learning, Adjusting, and Improving",
    date: "2025-01-23 15:23:00",
    content: <>
      <h2>Keyless Works: Learning, Adjusting, and Improving</h2>
      <p>Today, I continued working on the <strong>keyless works</strong>, diving deeper into the details and learning more with every step. Along the way, I discovered a mistake in my design that needed fixing: I had planned for just one intermediate wheel, which made the stem’s square sliding part way too long!</p>
      <p>To solve this, I’ll be splitting the design into two intermediate wheels tomorrow and shortening the sliding path. This adjustment should improve the overall functionality and bring everything into better alignment. 😊</p>
      <p>It’s all part of the journey—learning, refining, and iterating until it works perfectly. Stay tuned as I put this new plan into action and keep moving forward!</p>
<h1>Cheers! 😅</h1>
      <br />
      <img src='images/blog/2025-01-23/00.jpg' alt='sketch' />
    </>
  },
  {
    title: "Keyless Works Woes: A Frustrating Roadblock",
    date: "2025-01-21 16:13:00",
    content: <>
      <h2>Keyless Works Woes: A Frustrating Roadblock</h2>
      <p>Today has been one of those days—crap and frustrating. 😩 I’ve hit an issue with the <strong>keyless works</strong>, and it’s proving to be a tricky one. The problem lies in joining the <strong>sliding pinion</strong> with the <strong>intermediate hand</strong> that connects to the minute wheel to set the time.</p>
      <p>On paper, it seemed straightforward, but in practice, the alignment and engagement between these components just isn’t working as planned. I’ve tried a couple of approaches, but nothing has clicked yet. It’s one of those challenges where you know there’s a solution—it’s just frustratingly out of reach for now. 😤</p>
      <p>I’ll take a step back, regroup, and do some more research to figure out the best way to address this. It’s moments like these that test your patience, but they’re also opportunities to learn and grow. Hopefully, tomorrow brings some fresh ideas and a way forward!</p>
      <p>If anyone has tips or insights, I’d love to hear them. For now, it’s time to step away and clear my head. Watchmaking sure knows how to keep you on your toes! 😅</p>
      <h1>Cheers! 😅</h1>
      <br />
      <img src='images/blog/2025-01-21/00.jpg' alt='sketch' />
    </>
  },
  {
    title: "Progress on the Winding Mechanism",
    date: "2025-01-20 15:33:00",
    content: <>
      <h2>Progress on the Winding Mechanism</h2>
      <p>Today, I’ve been focusing on polishing the top part of the winding mechanism. It’s coming together nicely, and seeing the refined surfaces start to shine is always satisfying. 😊</p>
      <p>Alongside the polishing, I’ve been trying out different materials to find the perfect balance between durability, machinability, and aesthetics. Each material brings its own set of challenges and opportunities, and it’s exciting to explore how they perform in such a precision-dependent component.</p>
      <p>That said, there’s one key part left to tackle: the crown gears for the sliding pinion. I’ll admit, I’m not entirely sure how to approach those yet. It’s a new challenge I’m eager to dive into, and I’m confident I’ll figure it out with some research and experimentation.</p>
      <p>Progress may be slow, but every step forward is a rewarding part of the journey. Stay tuned as I tackle the final pieces and continue refining this essential mechanism!</p> <h1>Laterz! 😊</h1>
      <br />
      <img src='images/blog/2025-01-20/03.jpg' alt='sketch' />
      <img src='images/blog/2025-01-20/01.jpg' alt='sketch' /> 
      <img src='images/blog/2025-01-20/02.jpg' alt='sketch' />
    </>
  },
  {
    title: "Focus on the Winding Pinion and Sliding Pinion",
    date: "2025-01-18 11:32:00",
    content: <>
      <h2>Focus on the Winding Pinion and Sliding Pinion</h2>
      <p>Today’s work revolved around two critical components of the winding mechanism: the <strong>winding pinion</strong> and the <strong>sliding pinion</strong>. These small but essential parts are at the heart of a smooth and reliable movement, and I wanted to ensure every detail was just right.</p>
      <p>The winding pinion is responsible for transmitting motion from the crown to the mainspring barrel, while the sliding pinion enables the switch between winding the mainspring and setting the time. Designing and refining these components requires balancing precision and functionality, especially in the tight constraints of the movement.</p>
      <p>It’s a rewarding challenge to see how these pinions interact and fit within the mechanism. The precision required highlights how every fraction of a millimeter matters in watchmaking, and it’s a reminder of why I love this craft. 😊</p>
      <p>I’m looking forward to continuing this work tomorrow and seeing how these components contribute to the winding mechanism as a whole. Stay tuned!</p>
      <h1>Laterz! 😊</h1>
      <br />
      <img src='images/blog/2025-01-18/00.jpg' alt='sketch' />
    </>
  },
  {
    title: "Learning, Precision, and a Break for Creativity",
    date: "2025-01-17 16:16:00",
    content: <>
      <h2>Learning, Precision, and a Break for Creativity</h2>
      <p>It’s been a busy time recently as I’ve been diving into the world of milling and machining parts—something totally new to me! The process of learning about micro-precision and bridging the gap between software designs and real-world results has been both challenging and fascinating. Every step feels like uncovering a new layer of possibilities. 😊</p>
      <p>But today, I’m excited to take a break from the technical learning and focus on something creative: the winding mechanism. It’s time to work on the stem, bridge, and the two wheels and pinions that bring the mechanism to life. There’s something deeply satisfying about switching gears and immersing myself in the intricate details of watchmaking.</p>
      <p>I’ll share updates on how it progresses and how all these elements come together. For now, it’s just great to get back to designing and building components with precision and care. Stay tuned!</p>
      <h1>This is of course all WIP 😊</h1>
      <br />
      <img src='images/blog/2025-01-17/01.jpg' alt='sketch' />
      <br />
      <img src='images/blog/2025-01-17/02.jpg' alt='sketch' />
    </>
  },
  {
    title: "First G-Code: Bringing Designs to Life",
    date: "2024-12-23 17:40:00",
    content: <>
      <h2>First G-Code: Bringing Designs to Life</h2>
      <p>Apologies for the silence lately! It’s been a challenging few days as I’ve been familiarizing myself with the <strong>NSCNC Elara</strong> and recovering from a stomach virus. Thankfully, I’m back on my feet and excited to share a major milestone—I wrote my first <strong>G-code</strong> manually! 🎉</p>
      <p>Instead of relying on CAM software, I decided to create the G-code in a text file from scratch, with the guidance of ChatGPT. This hands-on approach helped me truly understand the fundamentals of CNC programming. My test involved milling a simple pattern in wax, a forgiving material perfect for beginners.</p>
      <p>Here’s the G-code I wrote, complete with comments to explain each line:</p>
      <div className='code' style={{whiteSpace: 'pre'}}>
        <p>G90 G17 G21       </p>
        <p>F300              </p>
        <p>G53 G0 Z0         </p>
        <p>G53 G0 X-100 Y35  </p>
        <p>G53 Z-20          </p>
        <p>G0 X0 Y0          </p>
        <p>G0 Z-15           </p>
        <p>G1 Z-23 F100      </p>
        <p>S3000 M3          </p>
        <p>G2 I-5 J0 F200    </p>
        <p>M5                </p>
        <p>G0 Z10            </p>
      </div>
      
      <p>The Elara, equipped with the <strong>E3000 controller</strong> and <strong>3060 AQC spindle</strong>, executed the code perfectly. Watching the machine follow my instructions precisely was an incredibly rewarding experience! 😊</p>
      <p>This exercise deepened my understanding of G-code and CNC operations, making me more confident about tackling more complex projects in the future. I’ll continue refining my skills and moving toward intricate designs for the watch components.</p>
      <p>Thank you for your patience during this brief hiatus—stay tuned for more updates as the journey unfolds!</p>
      <h1>😊</h1>
      <br />
      <div>
        <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
          <source src="images/blog/2024-12-23/00.mov" type="video/mp4"></source>
        </video>
      </div>
    </>
  },

  {
    title: "First Tests with the NSCNC Elara",
    date: "2024-12-13 14:13:00",
    content: <>
    <h2>First Tests with the NSCNC Elara: Wax Milling Adventures!</h2>
    <p>The day has finally come—my first tests with the <strong>NSCNC Elara</strong> are underway, and I couldn’t be more excited! 🎉 This is my very first experience with milling, and I decided to start with wax as the material for these initial tests.</p>
    <p>The Elara is an absolute marvel to work with. Even as a complete beginner, I’m amazed at how intuitive the setup feels and how precise the machine operates. Watching the spindle carve through the wax with precision is an experience like no other. 😊</p>
    <p>While I’m still learning the ropes, this first test has been incredibly encouraging. Wax is a forgiving material to work with, which makes it the perfect starting point for understanding the machine’s capabilities and refining my technique.</p>
    <p>I can already see how this machine will open up countless possibilities for creating intricate watch components. These early steps are just the beginning of what promises to be an incredible journey!</p>
    <p>Stay tuned as I continue exploring, learning, and sharing updates from these exciting first days with the Elara. 🚀</p>

    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={false} muted={false}>
        <source src="images/blog/2024-12-13/00.mov" type="video/mp4"></source>
      </video>
    </div>
    <br />
    <img src='images/blog/2024-12-13/00.jpg' alt='sketch' />
    <br />
    </>
  },
  {
    title: "Blueing Metal Test: First Experiments",
    date: "2024-12-10 15:17:00",
    content: <>
      <h2>Blueing Metal Test: First Experiments</h2>
      <p>Today, I embarked on my first test in blueing metal, and it was an exciting learning experience! I started with <strong>O-1 steel</strong> in 1/16" thickness, which I sourced recently. (I’m still on the hunt for <strong>W-1 steel</strong>, so I’ll test that once I manage to find a reliable supplier.)</p>
      <p>For this experiment, I also put my new <strong>Evenheat KH Series 13.5"</strong> oven to work, and with the guidance of ChatGPT, I developed the following steps for the process:</p>
      <ul>
        <li><strong>Step 1:</strong> Ramp rate: 300°C, Setpoint: 300°C, Hold time: 10 minutes</li>
        <li><strong>Step 2:</strong> Ramp rate: 137.38°C, Setpoint: 300°C, Hold time: 18 minutes</li>
        <li><strong>Step 3:</strong> Ramp rate: AFAP (as fast as possible), Setpoint: 150°C, Hold time: 45 minutes</li>
      </ul>
      <p>The results? To my surprise, they turned out better than expected! 🎉</p>
      <p>The steel developed a beautiful, uniform blue finish that exceeded my initial hopes for this experiment. This was a great first step toward mastering the blueing process for watch components, and I’m eager to refine the technique further. 😊</p>
      <p>Stay tuned as I continue testing with different materials and tweaking the steps. If you have any tips or insights, I’d love to hear them!</p>
      <h1>Cool! 😊</h1>
      <br />
      <img src='images/blog/2024-12-10/00.jpg' alt='sketch' />
      <br />
      <img src='images/blog/2024-12-10/01.jpg' alt='sketch' />
    </>
  },
  {
    title: "Fixing Yesterday's Rookie Mistake",
    date: "2024-11-29 15:43:00",
    content: <>
      <h2>The Elara 2 Has Arrived: A World of Possibilities!</h2>
      <p>I'm thrilled to announce the arrival of our new <strong>Elara 2</strong> machine! 🎉 This advanced 4-axis CNC mill, equipped with the <strong>E3000 controller</strong> and the <strong>3060 AQC spindle</strong>, is set to revolutionize our production capabilities. 😊</p>
      <p>The Elara 2 boasts a redesigned frame for enhanced stability and durability, along with an updated control system for more intuitive operation. This precision powerhouse allows for unparalleled accuracy, making it perfect for crafting intricate components for watchmaking.</p>
      <p>With the <strong>E3000 controller</strong> and <strong>3060 AQC spindle</strong>, the machine is capable of handling a variety of materials, from soft metals like brass and gold to tougher materials like stainless steel and titanium. Its 4-axis capabilities open the door to complex geometries and unique designs, making it ideal for producing one-of-a-kind timepieces or restoring hard-to-find parts.</p>
      <p>Another highlight is the Elara 2's compatibility with high-precision workpiece holders, such as the MaxxMacro system, ensuring every detail is machined with meticulous accuracy. I’m particularly excited to see how it performs on delicate parts like gears, bridges, and even custom decorative elements.</p>
      <p>The possibilities feel endless, and I can’t wait to put this machine to work. That said… there’s a catch. It turns out I need an air compressor to run the spindle properly, and I don’t have one yet. 😅</p>
      <p>So, while the Elara 2 is here and ready to go, I’m currently on the hunt for the perfect air compressor to unlock its full potential. If you have any recommendations, feel free to share—I could use the help! 😊</p>
      <p>Stay tuned as I sort this out and get the machine up and running. Here’s to precision, innovation, and, hopefully, finding an air compressor soon! 🎉</p>

      <h1>😊</h1>
      <br />
      <img src='images/blog/2024-11-29/00.jpg' alt='sketch' />
      <br />
      <img src='images/blog/2024-11-29/01.jpg' alt='sketch' />
    </>
  },
  {
    title: "Exploring KIF and Securing the Wheels",
    date: "2024-11-19 15:00:00",
    content: <>
      <h2>Exploring KIF and Securing the Wheels</h2>
      <p>I’ve heard great things about KIF systems, so I decided to dive in and do some hands-on investigation. After some research, I went ahead and implemented two models: the <strong>9131-5903</strong> (Sous, for the dial side) and the <strong>9131-5904</strong> (Sus, for the plate side). These components are known for their excellent shock absorption, and I’m excited to see how they perform in the movement design.</p>
      <p>While working on this, I also noticed one of the wheels felt a bit insecure. To address that, I added an additional leg to give it more stability. It’s always rewarding to spot potential weak points early and strengthen them before they become an issue later on. 😊</p>
      <p>With these updates, the movement is feeling more robust and ready to take on its next challenges. As always, learning and iterating is part of the fun—stay tuned for more progress soon!</p>

      <h1>😊</h1>
      <br />
      <img src='images/blog/2024-11-19/00.jpg' alt='sketch' />
      <img src='images/blog/2024-11-19/01.jpg' alt='sketch' />
      <img src='images/blog/2024-11-19/03.jpg' alt='sketch' />
      <img src='images/blog/2024-11-19/04.jpg' alt='sketch' />
    </>
  },
  {
    title: "Fixing Yesterday's Rookie Mistake",
    date: "2024-11-17 18:18:00",
    content: <>
      <h2>Fixing Yesterday's Rookie Mistake</h2>
      <p>Remember the little mishap I mentioned yesterday, where the barrel ended up on the opposite side of the stem position? 😅 Well, I’m happy to report that it’s all fixed now! 🎉</p>
      <p>Thanks to Fusion 360’s joint system, repositioning the train of wheels and moving the barrel to the correct side was straightforward. It’s one of those moments where I’m grateful for modern tools making the process so much smoother than it could have been. 😊</p>
      <p>This fix was a great reminder that every mistake—no matter how rookie—is an opportunity to learn and refine the process. With everything now back in place, I can confidently move forward with the plates and winding mechanism. Onward to the next challenge!</p>
      <h1>😊</h1>
      <br />
      <img src='images/blog/2024-11-17/00.jpg' alt='sketch' />
    </>
  },
  {
    title: "Rookie Mistake on the Plates",
    date: "2024-11-13 13:07:00",
    content: <>
      <h2>Rookie Mistake on the Plates</h2>
      <p>I started working on the plates today to prepare for the winding mechanism, but... I made a rookie mistake! 😅 The barrel ended up on the opposite side of the stem position—oops! 😂</p>
      <p>Thankfully, Fusion 360’s joint system saved the day. All I need to do is reposition the train and move the barrel to the correct side. That’s inexperience for you, folks! Hehe, every mistake is just another step in the learning process. </p>


      <h1>😊</h1>
      <br />
      <img src='images/blog/2024-11-16/00.jpg' alt='sketch' />
    </>
  },
  {
    title: "Movement placement",
    date: "2024-11-13 13:07:00",
    content: <>
      <h2>Progress Update: Jewel Placement on fourth wheel</h2>
      <p>Today, I made a few adjustments to the watch’s tree structure and set the jewel in place for the fourth wheel. It was a straightforward, productive day—sometimes, the simplest tasks bring the most satisfaction!</p>

     <h1>🤩</h1>
      <br />
      <img src='images/blog/2024-11-13/00.jpg' alt='sketch' />
    </>
  },
  {
    title: "Movement placement",
    date: "2024-11-12 13:07:00",
    content: <>
      <h2>Progress Update: Adjustments and Jewel Placement</h2>
      <p>Today, I discovered that my mainspring barrel was a bit oversized, actually protruding through the case. 🔍 This meant I needed to rearrange the positioning of the wheels to make everything fit properly—fingers crossed, they won’t end up too tight!</p>
      <p>On a positive note, it looks like I’ll be able to use the same type of jewels for the center, third, and fourth wheels, which simplifies things nicely. 😊</p>
      <p>Today, I added jewels to the third wheel, and tomorrow, I’ll continue with the fourth. I’ll also begin planning the bridges and plates, bringing the design another step closer to completion.</p>

     <h1>🤩</h1>
      <br />
      <img src='images/blog/2024-11-12/00.jpg' alt='sketch' />
      <img src='images/blog/2024-11-12/01.jpg' alt='sketch' />
    </>
  },
  {
    title: "Choosing the Perfect Jewels",
    date: "2024-11-11 17:20:00",
    content: <>
      <h2>Choosing the Perfect Jewels for Our Watch Movement</h2>
      <p>Today, I wanted to dive into an aspect of our movement design that’s been carefully thought out: choosing the right jewels. It’s a vital step, and I’ve spent a lot of time weighing options to ensure each component performs flawlessly, following the NIHS (NIHS 04-04F, NIHS 94-10, NIHS-05-01, NIHS-05-03) standards for precision and durability in watchmaking.</p>
      <p>For this design, I’ll be using straight jewels on the dial side and olive (also known as bombe) ring jewels on the plate side. Each type has its own advantages, primarily in reducing friction and wear, which is essential for ensuring smooth movement in the train of wheels. The NIHS standards provided key guidelines for evaluating each jewel type’s fit and performance within the system.</p>
      <p>To give myself some leeway during production, I ordered 18 jewels of each type: the R53.30 and R50.80K from swissjewel.com. This flexibility will help address any fine adjustments we need to make as we finalize the fit and finish of the train. These jewels are precisely sized per NIHS tolerances, so having extras means we can adapt without compromising progress or quality.</p>
      <p>It’s a small but critical detail, as every watchmaker knows, but one that will ultimately contribute to a smoother, more reliable movement. Stay tuned as I continue to refine this design, one jewel and shaft at a time, always with NIHS standards as our compass!</p>
      <p>I'm also thinking of using the same jewels for the center, third and fourth wheels.</p>
     
     <h1>🤩</h1>
      <br />
      <img src='images/blog/2024-11-11/00.jpg' alt='sketch' />
      <img src='images/blog/2024-11-11/01.jpg' alt='sketch' />
    </>
  },
  {
    title: "Life Update",
    date: "2024-11-10 11:23:00",
    content: <>
      <h2>Life Update and New Adventures in Watchmaking</h2>

      <p>Hello everyone!</p>

      <p>It's been a little quiet on <a href="https://fezuone.co">Fezuone.co</a> recently, and I wanted to share why. My wife and I just welcomed our beautiful daughter into the world, and we’ve been soaking up every precious moment with her. It’s been an incredible experience, and I’m truly grateful for this new chapter in life.</p>

      <p>But while I’ve had to step away from the workbench a bit, I’ve been putting any free moments to good use—diving deep into the world of tolerances. This focus on precision will be crucial as I work on designing the shafts and jewels for the train of wheels. It’s been a fascinating study, especially as I consider how these micro-measurements directly affect a watch’s reliability and performance.</p>

      <p>Thank you for your patience, and I look forward to sharing more soon. In the meantime, know that this project—and my passion for watchmaking—is very much alive, just evolving alongside life’s latest adventure!</p>
    </>
  },
  {
    title: "Life Update",
    date: "2024-11-10 11:23:00",
    content: <>
      <h2>Life Update and New Adventures in Watchmaking</h2>

      <p>Hello everyone!</p>

      <p>It's been a little quiet on <a href="https://fezuone.co">Fezuone.co</a> recently, and I wanted to share why. My wife and I just welcomed our beautiful daughter into the world, and we’ve been soaking up every precious moment with her. It’s been an incredible experience, and I’m truly grateful for this new chapter in life.</p>

      <p>But while I’ve had to step away from the workbench a bit, I’ve been putting any free moments to good use—diving deep into the world of tolerances. This focus on precision will be crucial as I work on designing the shafts and jewels for the train of wheels. It’s been a fascinating study, especially as I consider how these micro-measurements directly affect a watch’s reliability and performance.</p>

      <p>Thank you for your patience, and I look forward to sharing more soon. In the meantime, know that this project—and my passion for watchmaking—is very much alive, just evolving alongside life’s latest adventure!</p>
    </>
  },
  {
    title: "Dial Eye Candy: A Little Refresh",
    date: "2024-11-02 10:52:00",
    content: <>
    <h2>Dial Eye Candy: A Little Refresh</h2>

    <p>Taking it easy today and having some fun with the dial design! I decided to update the hour markers, added a touch of <strong>Super-Luminova</strong>, and simplified the outer ring pattern a bit. To capture the details, I played around with some <em>depth of field</em> using an 85mm lens.</p>

    <p>Not totally in love with the empty look right now—feels like it’s missing something. But hey, once the <strong>main plate</strong> and <strong>bridges</strong> are in, it should all come together!</p>

    <h1>🤩</h1>
    <br />
    <img src='images/blog/2024-11-02/00.jpg' alt='sketch' />
    <img src='images/blog/2024-11-02/01.jpg' alt='sketch' />
    <img src='images/blog/2024-11-02/02.jpg' alt='sketch' />

    </>
  },
  {
    title: "Balance and renders",
    date: "2024-11-01 15:52:00",
    content: <>
    <h2>Balance and Super-Luminova Experiment</h2>

    <p>Tried out a new <strong>Super-Luminova</strong> pattern on the dial—still not totally convinced, but it's a start!</p>

    <p>On the bright side, I finally nailed the <em>roller jewel positioning</em>, which let me add the <strong>safety pin</strong>. Turns out, it's all about keeping the balance locked when the watch takes a knock—who knew?</p>

    <p>Next step: raising the balance just a touch so it’s more visible. Stay tuned!</p>
    <h1>🌟</h1>
    <br />
    <img src='images/blog/2024-11-01/00.jpg' alt='sketch' />
    <img src='images/blog/2024-11-01/01.jpg' alt='sketch' />
    <img src='images/blog/2024-11-01/02.jpg' alt='sketch' />

    </>
  },
  {
    title: "Refactorings",
    date: "2024-10-31 15:52:00",
    content: <>
    
    <h2>Refactoring Adventures: Building a Movement from the Ground Up!</h2>

    <p>It took some serious tinkering (and a few dozen rebuilds of the train of wheels!), but I finally found a smoother approach—first setting up the <strong>wheel positions</strong>, then handling the joints, and finally rotating (or folding) the wheels into place. This way feels way more intuitive!</p>

    <p>I also revisited the <strong>gear ratios</strong> in the train of wheels. Now, the hour wheel fits perfectly with the center wheel pinion—such a satisfying click when it all aligns! And yes, I've finally added the <em>seconds hand</em> to complete the time-telling trio.</p>

    <p>The <strong>case got a makeover</strong> too! It now wraps comfortably around the wrist instead of just sitting there like a flat disc. A little curve really makes it come to life on the wrist.</p>

    <p>Next up, it's time to <strong>add the pinions</strong> to the wheels and get those jewels in place—so exciting! And after that? <em>The bridges!</em> Can't wait to see it all come together!</p>
    <p>I gotta give a shoutout to <strong>Alberto Sicco, Charles Fontaimpe</strong> from generaleressorts.com, I had a pretty bad mistake in the mainspring barrel, thanks to his comment I could fix it!</p>
    <h1>😊</h1>
    <br />
    <img src='images/blog/2024-10-31/000.jpg' alt='sketch' />
    <img src='images/blog/2024-10-31/00.jpg' alt='sketch' />
    <img src='images/blog/2024-10-31/01.jpg' alt='sketch' />
    <img src='images/blog/2024-10-31/02.jpg' alt='sketch' />
    <img src='images/blog/2024-10-31/03.jpg' alt='sketch' />

    </>
  },
  {
    title: "Funky wheels",
    date: "2024-10-30 16:20:00",
    content: <>
    

    <h1>Because why not</h1>
    <p>Testing out a quick different design for the wheels!</p>


    <img src='images/blog/2024-10-30-1/00.jpg' alt='sketch' />
    <img src='images/blog/2024-10-30-1/01.jpg' alt='sketch' />

    <h1>🤯</h1>
    </>
  },
  {
    title: "Previewing a potential layout",
    date: "2024-10-30 12:27:00",
    content: <>
    

    <h1>This is Exciting!</h1>
    <p>This layout is finally set up to show hours, minutes, and seconds correctly. Can you believe it's taken about a month to get here? Just one more detail to nail down (see last pic)!</p>

    <h2>Current Status:</h2>
    <ul>
        <li>Wheels aren't designed yet, so they're still looking solid and rough.</li>
        <li>Bridges and jewels aren't in place yet either.</li>
        <li>Unexpected plot twist: I'm officially in love with the balance wheel ❤️</li>
    </ul>

    <img src='images/blog/2024-10-30/00.jpg' alt='sketch' />
    <img src='images/blog/2024-10-30/01.jpg' alt='sketch' />
    <img src='images/blog/2024-10-30/02.jpg' alt='sketch' />

    
    <h2>Here's the "But"…</h2>
    <p>The center wheel pinion is where the magic happens for the coaxial hands. It drives a secondary wheel, and the cannon pinion is friction-fitted right on top of that secondary wheel.</p>

    <h3>So, what's the issue?</h3>
    <p>I'm struggling to get the dedendums of the center wheel pinion to line up properly with the cannon-wheel, especially since its axis is actually the fourth wheel. Gotta figure this one out to keep everything aligned!</p>
    
    <img src='images/blog/2024-10-30/03.jpg' alt='sketch' />
    <h1>🤯</h1>
    </>
  },
  {
    title: "Seeing everything together uncover errors",
    date: "2024-10-28 17:43:00",
    content: <>
    <h1>Seeing everything together uncover errors</h1>
    
    <p>These wheels make all the ratios that will display hours, minutes, seconds in the same axis</p>
    <p>Im really glad I layed them over together</p>
    <p>why? </p>
    <p>See that wheel with the curvy cutouts? well the cannon pinion fits in it, and with the current sizes I wont be able to fit the two wheels, need to rework the teeth counts!</p>
    <img src='images/blog/2024-10-28/00.jpg' alt='sketch' />
    </>
  },
  {
    title: "Hands fitting",
    date: "2024-10-25 13:39:00",
    content: <>
    <h1>Hands fitting. </h1>
    
    <p>The cyclinders where made to NIHSg 24-10</p>
    <p>The wheel mechanism under the cylinders is still WIP</p>
    <img src='images/blog/2024-10-26/00.jpg' alt='sketch' />
    </>
  },
  {
    title: "Following NIHS - Refactoring",
    date: "2024-10-25 16:21:00",
    content: <>
    <h1>Following NIHS - Refactoring. </h1>
    
    <p>Been closely following NIHS standards, 
    with the help of chatgpt, and Alex from <a href='https://watchrepairtutorials.com' target='_blank'>watchrepairtutorials.com</a> calculated precise mainspring dimensions: </p>
    <ul>
      <li>Thickness: 0.13 mm</li>
      <li>Length: 551 mm</li>
      <li>Width: 1.33 mm</li>
    </ul>
    <p>
      Additionally, I ensured the correct fit of the barrel arbor, with a total height of 1.58 mm to fit smoothly inside the barrel, maintaining a clearance of 0.1 mm on both the top and bottom to prevent friction. I've also decided on the use of a bride rapportée (inserted bridle) for its superior control in automatic winding.
      The technical details I've explored, including the use of Nivaflex mainsprings and calculations based on NIHS 11-02 standards, bring us closer to a well-engineered movement.
      
    </p>
    <p>Stay tuned for more updates as I push forward with the prototype phase!</p>

    <p>Barrel arbor, Mainspring barrel</p>
    <img src='images/blog/2024-10-25/00.jpg' alt='sketch' /> 

    <p>Escapement (yep missing the safety pin) -- target: 28800 vph</p>
    <p>Im thinking of going with KIF, but need to explore more</p>
    <img src='images/blog/2024-10-25/01.jpg' alt='sketch' />

    <p>Train of wheels -- with proper reduction for the fourth wheel and minute wheel</p>
    <img src='images/blog/2024-10-25/02.jpg' alt='sketch' />
    
    </>
  },
  {
    title: "Quick exploration of top and bottom incabloc",
    date: "2024-10-21 06:32:00",
    content: <>
    <h1>Quick exploration of top and bottom incabloc. </h1>
    
    <p>I modeled these based on the incabloc website, hopefully I can source them from somewhere:</p>
    <p>sus 100.12.257</p>
    <img src='images/blog/2024-10-21/00.jpg' alt='sketch' /> 
    <p>sous 103.20</p>
    <img src='images/blog/2024-10-21/01.jpg' alt='sketch' />
    
    </>
  },
  {
    title: "Exploring 28800 vph",
    date: "2024-10-20 14:03:00",
    content: <>
    <h1>Exploring 28800 vph</h1>
    <p>
      It seems 36000vph, forces me to add a third and fourth intermediary wheels to the train, Im not sure I like that.
    </p>
    <p>I decided to explore 28800 vph, following NIHS rules actually simplifies the process (NIHSG_34-04, NIHSG_34-25, NIHSG_35-10, NIHSG_35-15, NIHSG_35-20)</p>
    <p>Please note that due to my exploration, these values are constantly changing</p>
    <table border={1} cellPadding={3} cellSpacing={0} style={{fontSize: 10}}>
      <tbody>
        <tr>
          <td>Balance wheel Inertia</td>
          <td>12.5mm</td>
        </tr>
        <tr>
          <td>Balance wheel Diameter</td>
          <td>10mm</td>
        </tr>
        <tr>
          <td>Hairspring D</td>
          <td>6mm</td>
        </tr>
        <tr>
          <td>Hairspring d</td>
          <td>1.3mm</td>
        </tr>
        <tr>
          <td>Hairspring h</td>
          <td>0.18mm</td>
        </tr>
        <tr>
          <td>Hairspring P</td>
          <td>0.17mm</td>
        </tr>
        <tr>
          <td>Hairspring K</td>
          <td>2.65 10^-2 N·mm³/rad</td>
        </tr>
      </tbody>
    </table>
     
      <p>Overall view with a candidate of Incabloc 100.11.310/0* , though maybe it's a bit thick 🤔</p>
      <img src='images/blog/2024-10-20/00.jpg' alt='sketch' /> 

      <p>Decided with a fendue virole (NIHSG_35-20), still need to figure out if going with laser-weld, or Pinning with a stick</p>
      <img src='images/blog/2024-10-20/01.jpg' alt='sketch' />
      <p>Balance and wheel (NIHSG_34-04)</p>
      <img src='images/blog/2024-10-20/02.jpg' alt='sketch' />
      <p>Balance that follow NIHSG_34-25 standard</p>
      <img src='images/blog/2024-10-20/03.jpg' alt='sketch' /> 
    </>
  },
  {
    title: "Fine-Tuning My Watch Movement: A Day of Progress",
    date: "2024-10-17 15:35:00",
    content: <>
    <h1>Fine-Tuning My Watch Movement: A Day of Progress</h1>
    <p>
      Today was a significant step forward in designing my 36,000 vph watch movement. With the invaluable help of ChatGPT, I tackled key areas like refining the balance wheel and hairspring, choosing the best materials, and fine-tuning my calculations.
    </p>
    <ol>
    <li>
      <h4>Balancing the Wheel and Hairspring</h4>
      <p>For my movement to function at 5 Hz, I needed the right balance wheel (8.25 mm, 6.3 mg·cm²) paired with a precise hairspring. After some detailed calculations, I determined the ideal CGS number (K) to be 22.43 dyne·cm²/rad (or 224.3 N·mm³/rad in SI). ChatGPT helped break down the complex formulas and get everything just right.</p>
    </li>
    <li>
    <h4>Material Selection</h4>
      <p>Choosing between Glucydur, beryllium copper, and brass was tricky. Glucydur offers superior stability, while brass is easier to machine. With ChatGPT's input, I weighed the pros and cons and made an informed decision for my balance wheel.</p>
    </li>
    <li>
      <h4>Perfecting the Numbers</h4>
      <p>Precision was key, so I worked through the NIHS standards to ensure my balance wheel tolerances were spot on, with ChatGPT guiding me through the calculations for tolerance classes like js7. This gave me confidence in my design's accuracy.</p>
    </li>
    <li>
      <h4>Sourcing</h4>
      <p>Im trying to source a hairspring now that I know the numbers, hopefully I find someone willing to sell them to me.</p>
    </li>
    </ol>
    
    <h3>TLDR: </h3>
    <p>Today's progress, supported by ChatGPT, brought me closer to realizing my high-frequency watch movement. From calculating the perfect hairspring to choosing materials, everything is falling into place!</p>
    <p style={{fontSize: 10}}>(yes, chatgpt summarized all our interaction today, hehe)</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-10-17/01.mov" type="video/mp4"></source>
      </video>
    </div>
    <div> 
      <img src='images/blog/2024-10-17/00.jpg' alt='sketch' /> 
      <img src='images/blog/2024-10-17/01.jpg' alt='sketch' />
    </div>
    </>
  },
  {
    title: "Mainspring and hairspring",
    date: "2024-10-16 15:47:00",
    content: <>
    <h1>Chatting with ChatGpt regarding some materials</h1>
    <p>TLDR; 301 steel will work for mainsprings and Nivarox for the hairspring</p>
    <p>It'd be cool to make it from scratch, e.g the mainspring, I'll need to:</p>
    <ol>
      <li>Stamp the Piece of Metal</li>
      <li>Anneal the Metal</li>
      <li>Harden the Metal</li>
      <li>Temper the Metal</li>
      <li>Coil the Mainspring</li>
    </ol>

    <div>Do I know how to do this, <strong>Nope</strong>, but sounds like a challenge 😃</div>
    </>
  },
  {
    title: "Quick viz of the escapement",
    date: "2024-10-15 16:27:00",
    content: <>
    <h1>Quick viz of escapement</h1>
    <p>I know this has been done thousands of times by many people, still is quite cool to understand</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-10-15/01.mov" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "Fork with proper angles and Roller jewel",
    date: "2024-10-15 13:40:00",
    content: <>
    <h1>Fork and Balance Roller Jewel.</h1>
    
    <p>The animation on the previous post helped a lot! I added to the script building the fork based on the bergeon jewels I'm gonna test, it will be easy to change later</p>
    <p>Also after receiving the book "The practical Escapement" by George Daniels, I discovered an update to the angles on generating the wheel and pallet to be able to reach 36000 vph. gonna try that</p>
    <p>These are the jewels Im thinking of:</p>
    <ul>
      <li>Roller pin: <a href="https://www.bergeon.swiss/catalog/product/view/id/2268530/s/pierre-ellipses-o-ext-30-mm-en-paquet-de-3-pieces/" target='_blank'>Bergeon 31715</a></li>
      <li>Pallet Entry stone: <a href="https://www.bergeon.swiss/catalog/product/view/id/2268546/s/pierre-levees-entrees-o-24-mm-en-paquet-de-3-pieces/" target='_blank'>Bergeon 30716</a></li>
      <li>Pallet Exit stone: <a href="https://www.bergeon.swiss/catalog/product/view/id/2268559/s/pierre-levees-sorties-o-24-mm-en-paquet-de-3-pieces/" target='_blank'>Bergeon 30717</a></li>
    </ul>
    <div> 
      <img src='images/blog/2024-10-15/00.jpg' alt='sketch' /> 
      <img src='images/blog/2024-10-15/01.jpg' alt='sketch' />
    </div>
    </>
  },
  {
    title: "Understanding transfer of energy",
    date: "2024-10-14 13:29:00",
    content: <>
    <h1>Transfer of energy</h1>
    
    <p>
      This took me longer than expected to understand. Turns out: 
      <ol>
        <li>The balance wheel gives a small push to the pallet fork, allowing it to release the escapement wheel.</li>
	      <li>The escapement wheel, powered by the mainspring, transfers energy to the pallet fork by sliding along the impulse faces of the pallet jewels.</li>
	      <li>This energy is delivered to the balance wheel, providing the necessary impulse to keep it oscillating, which is why optimizing the impulse angle is important for efficiency.</li>
      </ol>
    </p>
    <p>
      Notes:
      <ul>
          <li>There are indeed two forces: one from the balance wheel, and one from the escapement wheel.</li>
          <li>The pallet fork angle is crucial to allow the roller jewel to enter, be caught by the fork, receive the impulse, and exit smoothly.</li>
          <li>The roller jewel must make contact with both sides of the pallet fork to transfer energy efficiently.</li>
          <li>The shape and strength of the roller jewel are important to handle impacts on both </li>
      </ul>
    </p>
    <h4>At least this is how I think it is, cant make any guarantees yet hehe</h4>
    <p>Here's a little animation I made</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={true} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-10-14/01.mp4" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "Added the calculations for the top part of the fork",
    date: "2024-10-11 11:56:00",
    content: <>
    <h1>Top part of fork</h1>
    
    <p>Added the proper rotation to the construction, so the shaft will bounce at the proper angle with a defined width.
      It will have 10° from side to side
    </p>
    
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-10-11/01.mp4" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "18 Wheel escapement",
    date: "2024-10-10 15:40:00",
    content: <>
    <h1>21600 vph</h1>
    
    <p>After continuing studying NIHS standards, I noticed a vph I was not familiar with... 21600vph
    Is it good? I dont know, but since the 24 wheel is what I need for the 28800 vph, and according to chatgtp it might be a lot, Im trying other options</p>
    The good thing is that I have setups now for 15-18-20-24 teeth escapements.
    <p>Also polishing the shape of the boot a bit more</p>
    
    <div>
      <p>Added a bunch more params to customize the wheel:</p>
      <img src='images/blog/2024-10-10/00.jpg' alt='sketch' />
      <p>18 tooth with the pallet forks in their angle</p>
      <img src='images/blog/2024-10-10/01.jpg' alt='sketch' />
      <p style={{marginTop: 32}}>Whole wheel with the stones</p>
      <img src='images/blog/2024-10-10/02.jpg' alt='sketch' />
    </div>
    </>
  },
  {
    title: "Continuing escapements",
    date: "2024-10-07 06:48:00",
    content: <>
    <h1>Escapement Wheel Continuing</h1>
    
    <p>Ok I could only take George Daniels's escapement so far with the 15+ teeth.</p>
    <p>Had to start modifying it, angles, symetry and more, to make a working escapement.</p>
    <p>I think I still need to improve some angles, heres a very quick animation</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-10-07/01.mp4" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "Escapement wheels!",
    date: "2024-10-05 06:48:00",
    content: <>
    <h1>Escapement Wheel</h1>
    
    <p>been making a script that follows George Daniel's Watchmaking, 
      particularly the "Semi Equidistant Club-Toothed escapement" 
      (which I think is a good option?), and it works great for 15 teeth escapements, 
      but, for a movement Im working on, I need 24 teeth, 
      Im mostly having trouble calculating the Locking face angle and the position of the left jewel.</p>
    
    <div>
      <h3>15 teeth escapement</h3>
      <img src='images/blog/2024-10-05/01.jpg' alt='sketch' />
      <h2 style={{marginTop: 32}}>20 teeth escapement</h2>
      <p>Im having trouble with the lock face angle</p>
      <img src='images/blog/2024-10-05/02.jpg' alt='sketch' />
      <h2 style={{marginTop: 32}}>24 teeth escapement</h2>
      <img src='images/blog/2024-10-05/03.jpg' alt='sketch' />
    </div>
    </>
  },
  {
    title: "Train of wheels in fusion, rocks!",
    date: "2024-09-29 10:32:00",
    content: <>
    
    <p>New gears in the movement! woho!</p>
    <p>I had to change the fourth wheel though, as the escapment didnt fit... oops</p>
    <div className='code'>
      <div>Main Barrel m=0.1298 z2=112 z1=16 ratio: 7:1 a=8.3072</div>
      <div>Center Wheel m=0.104 z2=80 z1=8 ratio: 10:1 a=4.576</div>
      <div>Third Wheel m=0.104 z2=60 z1=8 ratio: 7.5:1 a=3.536</div>
      <div>Fourth Wheel m=0.1 z2=60 z1=7 8.57:1 a=3.35</div>
    </div>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-30/01.mp4" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "Train of wheels in fusion, rocks!",
    date: "2024-09-29 15:32:00",
    content: <>
    <h1>🤯</h1>
    <p>Wow fusion is quite powerful,</p>
    <p>Here Im learning the Joint function, </p>
    <p>This will allow me to position the train of wheels exactly as I will like it, keeping the circle dedendum of each gear in place for the gears</p>
    <div className='code'>
       <div>Main Barrel:</div>
       <div>m=0.1298, Z=112, z=16</div>
       <div>Center Wheel:</div>
       <div>m=0.104, Z=80, z=8</div>
       <div>Third Wheel:</div>
       <div>m=0.104, Z=60, z=8</div>
       <div>Fourth Wheel:</div>
       <div>m=0.065, Z=60, z=7</div>
    </div>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-29/01.mov" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "NIHS-2030: Wheel and Pinion",
    date: "2024-09-28 22:00:00",
    content: <>
    <h3>NIHS-2030: Wheel and Pinion</h3>
    <p>Made this script, that creates a wheel and pinion using NIHS 20-30 standards</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-28/01.mov" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "Which gear standards are there?",
    date: "2024-09-25 08:44:00",
    content: <>
    <h3>NIHS 20-01 NIHS 20-02 NIHS 20-10</h3>
    <p>Recommended in the book "Wheel & Pinion Cutting in Horology"</p>
    <p>This look amazing, but apparently there's NIHS 20-30 now, which is the latest, just purchased it, but I dont think I can open source that one</p>
    <p>As is, it works and follows the rules, but will move to 20-30 and make it awesome.</p>
    <h4 style={{display: 'flex', alignItems: 'center'}}>
      <span style={{fontSize: 32}}><i className={"fa-brands fa-github"}></i></span>
      <a href="https://github.com/fzuleta/fusion-360-cycloidal-gears-nihs" target='_blank'>See github project</a>
    </h4>
    <img src='images/blog/2024-09-25/01.jpg' alt='sketch' />
    <img src='images/blog/2024-09-25/02.jpg' alt='sketch' />
    </>
  },
  {
    title: "Exploring Gears: British Standard 978 part 2",
    date: "2024-09-23 08:44:00",
    content: <>
    <p>Recommended in the books "Watchmaking" by George Daniels, and "Wheel & Pinion Cutting in Horology"</p>
    <p>These are a nice set, but it seems NIHS is also an option, will explore</p>
    <h4 style={{display: 'flex', alignItems: 'center'}}>
      <span style={{fontSize: 32}}><i className={"fa-brands fa-github"}></i></span>
      <a href="https://github.com/fzuleta/fusion-360-cycloidal-gears-BS-978-part-2" target='_blank'>See github project</a>
    </h4>
    <img src='images/blog/2024-09-23/01.jpg' alt='sketch' />
    </>
  },
  {
    title: "Exploring making a movement",
    date: "2024-09-22 09:57:00",
    content: <>
    <p>Been exploring understanding how a movement is deeply made, realized a whole bunch of things, like... my understanding of gears is wrong</p>
    <p>I will need to re-make all these gears</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-22/03.mov" type="video/mp4"></source>
      </video>
    </div>
    <p>A Balance staff</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-22/02.mov" type="video/mp4"></source>
      </video>
    </div>
    <p>A Barrel arbor (you can see it installed in the first video)</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-22/01.mov" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "Shrinking a bit the border",
    date: "2024-09-07 11:15:00",
    content: <>
    <p>Shrinking a bit strap metal border, so the watch feels a bit more round, I like it a bit more.</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-07/01.mp4" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "Strap metal border to hide holes",
    date: "2024-09-06 15:51:00",
    content: <>
    <p>Working on the strap metal border.</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-06/01.mp4" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "Strap holder thingy",
    date: "2024-09-05 15:08:00",
    content: <>
    <p>Working on the strap holder thingy, still missing the inside spring,</p>
    <p>Also added a little bit of a chamfer to the case, not sure if this is ok for the cnc yet.</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-05/01.mp4" type="video/mp4"></source>
      </video>
    </div>
    <div>
    <img src='images/blog/2024-09-05/01.jpg' alt='sketch' />
    <img src='images/blog/2024-09-05/02.jpg' alt='sketch' />
    </div>
    </>
  },
  {
    title: "Hands",
    date: "2024-09-04 15:08:00",
    content: <>
    <p>Playing around with the Dial and hands, Im leaving a whole in the center to add the super-luminova.</p>
    <p>Tricky measurements with the micrometric extrudes, it's time to start thinking about the movement.</p>
    <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
      <source src="images/blog/2024-09-04/01.mp4" type="video/mp4"></source>
    </video>
    </>
  },
  {
    title: "Learning Fusion 360",
    date: "2024-09-03 15:08:00",
    content: <>
    <p>Total newb here, imported the svg into fusion 360, started playing around.</p>
    <p>Waiting for the NSCNC Elara, Mira and Lathe to start making some tests!</p>
    <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
      <source src="images/blog/2024-09-03/reloj.mp4" type="video/mp4"></source>
    </video>
    </>
  },
  {
    title: "Paper test",
    date: "2024-08-27 06:00:00",
    content: <>
    <p>Paper tests!.</p>
    <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={false} muted={false}>
      <source src="images/blog/2024-08-27/IMG_3732.MOV" type="video/mp4"></source>
    </video>
    </>
  },
  {
    title: "Study Time",
    date: "2024-08-26 06:00:00",
    content: <>
    <p>Gonna need to level up.</p>
    <img src='images/blog/2024-08-26/01.jpg' alt='sketch' />
    </>
  },
  {
    title: "Second Sketches",
    date: "2024-08-25 10:40:00",
    content: <>
    <p>The adventure continues in Adobe Illustrator.</p>
    <p>Here are some sketch followups (yeah It looks aweful, but let's keep on going, I can see a promise in the proportions and future):</p>
    <p>Im thinking, maybe have a seethrough dial, leather strap, and funky looking hands.</p>
    <img src='images/blog/2024-08-25/01.jpg' alt='sketch' />
    </>
  },
  {
    title: "Total Newb",
    date: "2024-08-24 14:40:00",
    content: <>
    <p>The adventure begins in a sketching software Im confortable with, Adobe Illustrator.</p>
    <p>As I know nothing about manufacturing, I'll take it easier on myself and do a watch without any complications</p>
    <p>Here are some initial sketches:</p>
    <img src='images/blog/2024-08-24/01.jpg' alt='sketch' />
    </>
  },
];

export const Blog = () => {
  const [all, setAll] = React.useState<IBlog[]>([...blogItems]);
  const [list, setList] = React.useState<IBlog[]>([]);
  const more = () => {
    const l = [...list];
    const a = [...all];
    for (let i = 0; i<30; i++) {
      const it = a.shift()
      if (!it) { continue; }
      l.push(it);
    }
    setList(l);
    setAll(a);
  }
  React.useEffect(() => {
    const l = [];
    const a = [...all];
    for (let i = 0; i<30; i++) {
      const it = a.shift()
      if (!it) continue;
      l.push(it);
    }
    console.log(`hello`, l)
    setList(l)
    setAll(a);
  }, []);
  
  return <section className='blog'>
  {list.map(it => <div key={`it-${it.date}-${it.title}`}>
  <BlogItem {...it}/>
  </div>)}

  <div className='controller'>
    {/* <a 
      href={`http://`} 
      onClick={(e) => {e.preventDefault(); previousPage()}}
      style={{marginRight: 8}}
      >Previous</a> */}
    <a 
      href={`http://`} 
      onClick={(e) => {e.preventDefault(); more()}}
      style={{marginLeft: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        <div>Load more </div>
        <i className={"fa-solid fa-caret-down"}></i>
      </a>
  </div>
  </section>
}
const BlogItem = (props: IBlog ) => {
  return <div className='content'>
    <div className='top'>
      <h3>{props.title}</h3>
      <span>{moment(props.date).format('YYYY/MM/DD HH:mm')}</span>
    </div>
    <div className='body'>
      { props.content }
    </div>
  </div>
}